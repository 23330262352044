import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDeltaMigration } from '../../../store/actions/Migration';

export default function DeltaMigrationTabPanelHome({ setSection }) {

    const dispatch = useDispatch();
    const { migration } = useSelector(st => st['Migration']);

    return (
        <div style={{ height: '50vh', padding: '0% 4% 4% 4%', display: 'flex' }}>
            <div style={{ width: '100%', height: '60vh', borderRadius: '8px' }}>
                <div style={{ marginTop: '20px', width: '100%' }}>
                    <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                        <thead style={{ fontSize: '16px' }}>
                            <tr>
                                <th style={{ padding: '10px 16px', width: '25%' }}>Name</th>
                                <th style={{ padding: '10px 16px', width: '25%' }}>Status</th>
                                <th style={{ padding: '10px 16px', width: '25%' }}>Last Activity</th>
                                <th style={{ padding: '10px 16px', width: '25%' }}></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '14px' }}>
                            {migration['deltaMigrations'] && migration['deltaMigrations'].length > 0 ? migration['deltaMigrations']?.map((deltaMigration, index) => {
                                let status = 'In-Progress';
                                if (['created', 'connected', 'field-mapping'].includes(deltaMigration['status'])) status = 'Drafted';
                                else if (['sample', 'activated', 'in-progress'].includes(deltaMigration['status']) && deltaMigration['inQueueTasks'] > 0) status = 'In-Progress';
                                else if (['sample', 'activated'].includes(deltaMigration['status']) && deltaMigration['inQueueTasks'] == 0) status = 'Stopped';
                                else if (['completed', 'in-progress'].includes(deltaMigration['status']) && deltaMigration['inQueueTasks'] == 0) status = 'Completed';
                                return (
                                    <tr key={index}>
                                        <td style={{ padding: '18px 16px' }}>{deltaMigration['name']}</td>
                                        <td style={{ padding: '12px 16px', display: 'flex', alignItems: 'center' }}>
                                            <i className={`icon-circle ${status}`}></i> {status}
                                            {/* <span style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: deltaMigration['statusColor'], marginRight: '8px' }}></span> */}
                                        </td>
                                        <td style={{ padding: '12px 16px' }}>{moment(deltaMigration['updatedAt']).format('lll')}</td>
                                        {/* TODO: Add a button to view the delta migration details */}
                                        <td style={{ padding: '12px 16px', color: '#2563EB', cursor: 'pointer' }} onClick={() => {
                                            dispatch(setDeltaMigration(deltaMigration));
                                            if (deltaMigration['paymentStatus'] == 'unpaid') setSection('setup');
                                            else setSection('summery');
                                        }}>View</td>
                                    </tr>
                                )
                            }) : <tr><td className='text-center' colspan="4"> <br /><br />No delta migraiton created yet!</td></tr>}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: '5%', display: 'flex', justifyContent: 'end' }}>
                    <button
                        style={{ fontSize: '14px' }}
                        className="btn-style-two" type="button"
                        onClick={() => setSection('overview')} >
                        Start new delta migration
                    </button>
                </div>
            </div>
        </div>
    );
}
