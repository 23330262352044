import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentStatusAction, createDeltaMigration, getMigration, setActiveTab } from '../../store/actions/Migration';

const PostPayment = (props) => {
    const stripe = useStripe();
    const dispatch = useDispatch();

    const {
        modalSetter: setModal,
        notificationSetter: setNotification,
        fullMigrationSetter: setPaymentStatus,
        notificationMessageSetter: setNotificationMessage,
        notificationSeveritySetter: setNotificationSeverity,
    } = props;

    const { activeTab } = useSelector(st => st['Migration']);

    const fireNotification = (status = 'success', message = 'Success! Payment received.') => {
        setNotificationSeverity(status);
        setNotificationMessage(message);
        setTimeout(() => setNotification(true), 3000);
    };

    useEffect(() => {
        if (!stripe) return;
        const urlSearchParams = new URLSearchParams(window['location']['search']);
        const migrationKey = urlSearchParams.get('migration');
        const newDeltaMigrationId = urlSearchParams.get('newDeltaMigrationId');
        const stripePISecret = urlSearchParams.get('payment_intent_client_secret');

        stripe.retrievePaymentIntent(stripePISecret).then(({ paymentIntent }) => {
            dispatch(getMigration({ key: migrationKey, type: 'none' }));
            switch (paymentIntent.status) {
                case 'succeeded':
                    fireNotification('success', 'Success! Payment received.');
                    dispatch(setPaymentStatusAction(urlSearchParams.get('migration'))); // Also add if it's a Delta Migration also use payment intent so it's not used multiple times

                    // Open Delta Migration Tab
                    if (newDeltaMigrationId) dispatch(createDeltaMigration({ deltaMigrationId: newDeltaMigrationId, key: migrationKey, paymentIntent }));
                    else {
                        setModal(true);
                        setPaymentStatus('paid');
                    }
                    break;

                case 'processing':
                    setPaymentStatus('processing');
                    fireNotification('info', "Payment processing. We'll update you when payment is received.");
                    break;

                case 'requires_payment_method':
                    setPaymentStatus('failed');
                    fireNotification('error', 'Payment failed. Please try another payment method.')
                    break;

                default:
                    break;
            }
        });
    }, [stripe]);
};

export default PostPayment;