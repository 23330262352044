
export default function DeltaMigrationTabPanelOverview ({setSection}) {
    return (
        <div style={{ height: '60vh', paddingLeft: '4%', paddingRight: '4%', paddingTop: '2%', paddingBottom: '4%', marginBottom: '4%', display: 'flex' }}>
            <div style={{ width: '50%', height: '60vh' }}>
                <span style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: '700' }}>Welcome to your Delta Migration!</span>
                <div style={{ marginTop: '2%' }}></div>
                <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '400' }}
                    >We’re excited to help you complete your Delta Migration, <br /> 
                    ensuring your data is seamlessly transferred while keeping <br />
                    everything up to date. A Delta Migration is the final step in your <br />
                    migration journey, designed to sync any new or updated data <br />
                    since the initial migration. This process ensures that your <br />
                    system stays current and fully operational with minimal <br />
                    downtime. <br />
                    <div style={{ marginTop: '2%' }}></div>
                    Our team is here to assist you throughout the process. If you <br />
                    have any questions or need support, don’t hesitate to reach <br />
                    out.
                    <div style={{ marginTop: '2%' }}></div>
                    Let’s get started and complete your migration successfully!
                </span>
                <div style={{ marginTop: '3%' }}></div>
                <button type="button" onClick={() => {setSection('setup')}} class='btn-style-two' style={{ borderRadius: '5px', width: '200px' }}>Get a quote here</button>
            </div>
            <div style={{ backgroundColor: 'rgba(232, 239, 255, 1)', borderRadius: '10px', width: '50%', height: '60vh', paddingTop: '1.8%', paddingBottom: '3%', paddingLeft: '3%', paddingRight: '3%' }}>
                <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '400' }}>
                    To make sure the migration goes smoothly, we recommend <br />
                    following these best practices before getting started:
                </span>
                <div style={{ marginTop: '4%' }}></div>
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <span style={{ color: 'rgba(0, 101, 255, 1)', fontWeight: 'bold', marginRight: '8px' }}>{'>'}</span>
                        <div>
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }}>
                                Verify your initial migration data
                            </span>
                            <br />
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '400' }}>
                                Double-check that your previously migrated data is <br />
                                accurate and complete before starting the Delta Migration.
                            </span>
                        </div>
                    </li>
                    <div style={{ marginTop: '4%' }}></div>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <span style={{ color: 'rgba(0, 101, 255, 1)', fontWeight: 'bold', marginRight: '8px' }}>{'>'}</span>
                        <div>
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }}>
                                Minimize changes during migration
                            </span>
                            <br />
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '400' }}>
                                Try to limit major data modifications (such as bulk updates 
                                or deletions) during the migration to avoid inconsistencies
                            </span>
                        </div>
                    </li>
                    <div style={{ marginTop: '4%' }}></div>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <span style={{ color: 'rgba(0, 101, 255, 1)', fontWeight: 'bold', marginRight: '8px' }}>{'>'}</span>
                        <div>
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }}>
                                Clean up unnecessary data
                            </span>
                            <br />
                            <span style={{ fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '400' }}>
                                Remove any redundant or outdated records to improve <br />
                                migration efficiency and prevent unnecessary data from <br /> 
                                being transferred.
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}