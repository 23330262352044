import React, { useState } from 'react';
import { baseURL } from '../../store/config';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const PaymentForm = (props) => {
  const { migrationKey, amount, setSection, isDelta, newDeltaMigrationId } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const stylizeUSD = (amount) => {
    const options = { style: 'currency', currency: 'USD', currencyDisplay: 'code' }
    const formattedAmount = Intl.NumberFormat("en-US", options).format(amount?.toFixed(2));
    return formattedAmount;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    let return_url = `${baseURL}/Dashboard?migration=${migrationKey}`;
    if(isDelta) return_url += `&newDeltaMigrationId=${newDeltaMigrationId}`;
    const { error } = await stripe.confirmPayment({ elements, confirmParams: { return_url }}); // TODO: Add delta migration

    if (error) setErrorMessage(error['message']);
    else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className='btn-style-three w-100'>Pay {stylizeUSD(amount)}</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default PaymentForm;