import dayjs from "dayjs";
import PostPayment from "../../post-payment";
import { Modal, ModalBody } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../../payment-form/index';
import React, { useEffect, useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import CustomStyleDatePicker from "../../date-picker";
import { useDispatch, useSelector } from 'react-redux';
import { getMigration, createDeltaMigration, initiateDeltaMigration } from '../../../store/actions/Migration';

export default function DeltaMigrationTabPanelSetup({ setSection }) {

    const dispatch = useDispatch();
    const [endDate, setEndDate] = useState(dayjs());
    const [startDate, setStartDate] = useState(dayjs());
    const [detailsModal, setDetailsModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [notification, setNotification] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('unpaid');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');
    const { migration, recordCount, stripeSecret, newDeltaMigration } = useSelector(st => st['Migration']);

    useEffect(() => {
        const data = { key: migration['key'], startDate, endDate };
        dispatch(initiateDeltaMigration(data));
    }, [startDate, endDate]);

    useEffect(() => { setStartDate(dayjs(migration['createdAt'])) }, [migration]);

    const createMigration = () => {
        const data = { deltaMigrationId: newDeltaMigration['_id'], key: migration['key'], migrationId: migration['_id'], startDate, endDate };
        dispatch(createDeltaMigration(data));
        setTimeout(() => setSection('home'), 3000);
    }

    const stripePromise = loadStripe(process['env']['REACT_APP_STRIPE_PUB_KEY']);
    const stripePISecret = new URLSearchParams(window['location']['search']).get('payment_intent_client_secret');

    return (
        <>
            {stripePISecret &&
                <Elements stripe={stripePromise}>
                    <PostPayment
                        modalSetter={setDetailsModal}
                        migrationGetter={getMigration}
                        notificationSetter={setNotification}
                        fullMigrationSetter={setPaymentStatus}
                        notificationMessageSetter={setNotificationMessage}
                        notificationSeveritySetter={setNotificationSeverity}
                    />
                </Elements>
            }
            <div style={{ height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '', width: '70%', height: '100%', paddingTop: '2%', paddingBottom: '4%', paddingLeft: '4%', paddingRight: '4%' }}>
                    <span style={{ color: '#01194D', fontSize: '16px' }}>Select date range to migrate:</span>
                    <div style={{ marginTop: '3%', display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ width: '50%' }}>
                            <span style={{ color: '#01194D', fontSize: '16px' }}>From:</span>
                        </div>
                        <div style={{ width: '50%', paddingLeft: '3%' }}>
                            <span style={{ color: '#01194D', fontSize: '16px' }}>To:</span>
                        </div>
                    </div>
                    <div style={{ height: '90%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <CustomStyleDatePicker date={startDate} setDate={setStartDate} />
                        <CustomStyleDatePicker date={endDate} setDate={setEndDate} />
                    </div>
                </div>
                <div style={{
                    backgroundColor: '#E8EFFF', width: '30%', height: '100%',
                    paddingTop: '1%', paddingBottom: '1%', paddingLeft: '4%', paddingRight: '4%'
                }}>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: '#01194D', opacity: '50%', fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }} >
                                Credits left
                            </span>
                        </div>
                        <span style={{ color: '#01194D', opacity: '50%', fontSize: '24px', fontWeight: '700' }}>0</span>
                    </div>
                    <hr /> */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: '#01194D', opacity: '50%', fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }} >
                                New Records Found
                            </span>
                        </div>
                        <span style={{ color: '#01194D', opacity: '50%', fontSize: '24px', fontWeight: '700' }}>0</span>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: '#01194D', opacity: '50%', fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }} >
                                Updated Records
                            </span>
                        </div>
                        <span style={{ color: '#01194D', opacity: '50%', fontSize: '24px', fontWeight: '700' }}>{recordCount}</span>
                    </div>
                    {/* <hr />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: '#01194D', opacity: '50%', fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }} >
                                Deleted Records
                            </span>
                        </div>
                        <span style={{ color: '#01194D', opacity: '50%', fontSize: '24px', fontWeight: '700' }}>0</span>
                    </div> */}
                    <hr />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: '#01194D', fontFamily: 'Work Sans', fontSize: '14px', fontWeight: '700' }} >
                                Price
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#01194D', fontSize: '24px', fontWeight: '700' }}>${stripeSecret?.['amount']}</span>
                            <span tyle={{ color: '#01194D', fontSize: '14px', fontWeight: '400' }}> &nbsp; USD</span>
                        </div>
                    </div>
                    <div style={{ marginTop: '6%' }}>
                        <button 
                            type="button" 
                            // onClick={newMigration} 
                            onClick={() => setPaymentModal(true)}
                            className='btn-style-two' 
                            style={{ 
                                width: '100%',
                                paddingLeft: '5px',
                                borderRadius: '0px',
                                paddingRight: '5px',
                            }}>
                                Purchase delta migration
                        </button>
                    </div>
                </div>
            </div>

            {/* ------------------Payment Modal----------------- */}
            <Modal
                isOpen={paymentModal}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <Elements stripe={stripePromise} options={{
                            clientSecret: stripeSecret?.['secret'],
                            // Fully customizable with appearance API.
                            appearance: {/*...*/ },
                        }}>
                            <PaymentForm migrationKey={migration['key']} amount={stripeSecret?.['amount']} setSection={setSection} isDelta={true} newDeltaMigrationId={newDeltaMigration['_id']} />
                        </Elements>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}