import Papa from 'papaparse';
import EventBus from 'eventing-bus';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import capitalize from 'lodash.capitalize';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import React, { act, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import AddProperty from './addProperty';
import { setHubspotMapping, setSelectedObjects, downloadCSV, setDownloadCSV, uploadFields, saveNewMapping, removeField, setSupportedObjects, updateErrorPopup, getFieldsMapping } from '../../store/actions/Migration';

const FieldMapping = () => {
    const dispatch = useDispatch();
    const { migration, downloadFields, supportedObjects, objectsFieldMapping, errorMessage } = useSelector(st => st['Migration']);

    const [action, setAction] = useState('');
    const [source, setSource] = useState('');
    const [newModal, setNewModal] = useState('');
    const [editField, setEditField] = useState({});
    const [hubspotMap, setHubspotMap] = useState({});
    const [hubspotProp, setHubspotProp] = useState({});
    const [newProperty, setNewProperty] = useState({});
    const [openDropdown, setOpenDropdown] = useState(false);
    const [salesforceProp, setSalesforceProp] = useState({});

    useEffect(() => {
        if (!objectsFieldMapping['providerFields']) dispatch(getFieldsMapping(migration['key']));
        if (Object.keys(supportedObjects).length == 0) dispatch(setSupportedObjects(migration['supportedObjects']));
    }, []);

    useEffect(() => {
        if (migration['hubspotMap']) setHubspotMap(migration['hubspotMap']);
    }, [migration]);

    useEffect(() => {
        if (downloadFields) {
            document.getElementById('Fields-CSV').click();
            setTimeout(() => dispatch(setDownloadCSV('')), 500);
        }
    }, [downloadFields]);

    const objectsDropdownChange = (e) => {
        let newSupportObj = supportedObjects;
        if (!supportedObjects.includes(e['target']['value'][0])) {
            newSupportObj = newSupportObj.concat(e['target']['value']);
            dispatch(setSupportedObjects([...supportedObjects, ...e['target']['value']]));
        } else {
            newSupportObj = newSupportObj.filter(x => x !== e['target']['value'][0]);
            dispatch(setSupportedObjects([...newSupportObj]));
        }
        dispatch(setSelectedObjects({ supportedObjects: JSON.stringify(newSupportObj), key: migration['key'] }));
    };

    const openNewHubProperty = (property, objectType, source) => {
        if (!salesforceProp?.[objectType]) return EventBus.publish('error', 'Please select Salesforce Property First!');

        if (property && property['label'] == 'Add New') {
            setAction('add');
            setSource(source);
            setNewModal(objectType);
        } else setHubspotProp({ ...hubspotProp, [objectType]: property });
    };

    const clickUploadFile = (objectType) => {
        document.getElementById(`mapping-${objectType}`)['value'] = '';
        document.getElementById(`mapping-${objectType}`).click();
    };
    const handleFileUpload = (e) => {
        if (e['target']['files'].length) {
            const file = e.target.files[0];
            const fileExtension = file?.type.split('/')[1];
            if (fileExtension != 'csv') return EventBus.publish('error', 'Please input a csv file');

            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const objectType = e.target.id.split('-')[1];
                const { data: csvData } = Papa.parse(target['result'], { header: true });
                dispatch(uploadFields({ objectType, key: migration['key'], csvData: JSON.stringify(csvData) }));
            };
            reader.readAsText(file);
        }
        e['target']['value'] = '';
    };

    const saveNewField = (objectType, sfObject) => {
        if (!hubspotProp?.[objectType]) return EventBus.publish('error', 'Please select HubSpot Property First!');
        if (!salesforceProp?.[objectType]) return EventBus.publish('error', 'Please select Salesforce Property First!');

        dispatch(saveNewMapping({ objectType, migrationKey: migration['key'], hubspotProp: hubspotProp[objectType], sourceProp: salesforceProp[objectType], sfObject }));

        setHubspotProp({ ...hubspotProp, [objectType]: {} });
        setSalesforceProp({ ...salesforceProp, [objectType]: {} });
    }

    const setHubspotMapFn = (key, value) => {
        hubspotMap[key] = value;
        setHubspotMap({ ...hubspotMap });
        dispatch(setHubspotMapping({ hubspotMap, key: migration['key'] }));
    }

    const deleteMapping = (key) => {
        let objectIndex = objectsData.findIndex(x => x['source'] == key);
        objectsData.splice(objectIndex, 1);
        setObjectsData([...objectsData]);

        delete hubspotMap[key];
        setHubspotMap({ ...hubspotMap });
        dispatch(setHubspotMapping({ hubspotMap, key: migration['key'] }));
    }

    let sourceTypes = {
        'boolean': [{ type: 'bool', fieldType: 'booleancheckbox' }],

        'int': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'long': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'double': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'percent': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'currency': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],

        'time': [{ type: 'datetime', fieldType: 'date' }, { type: 'string', fieldType: 'text' }, { type: 'date', fieldType: 'date' }],
        'date': [{ type: 'date', fieldType: 'date' }, { type: 'string', fieldType: 'text' }],
        'datetime': [{ type: 'datetime', fieldType: 'date' }, { type: 'string', fieldType: 'text' }, { type: 'date', fieldType: 'date' }],

        'id': [{ type: 'string', fieldType: 'text' }],
        'string': [{ type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
        'textarea': [{ type: 'string', fieldType: 'textarea' }, { type: 'string', fieldType: 'text' }],

        'reference': [{ type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }], // cannot be mapped to string || we can SKIP this || Grey them and show some text
        'picklist': [{ type: 'enumeration', fieldType: 'select' }, { type: 'enumeration', fieldType: 'booleancheckbox' }, { type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
        'multipicklist': [{ type: 'enumeration', fieldType: 'select' }, { type: 'enumeration', fieldType: 'booleancheckbox' }, { type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
    }

    const [objectsData, setObjectsData] = useState([
        { objectType: 'Deal', plural: 'deals', sources: 'Opportunities', source: 'Opportunity' },
        { objectType: 'Contact', plural: 'contacts', sources: 'Contacts', source: 'Contact' },
        { objectType: 'Contact', plural: 'contacts', sources: 'Leads', source: 'Lead' },
        { objectType: 'Company', plural: 'companies', sources: 'Accounts', source: 'Account' },
    ]);

    return (
        <div className='connect-inner'>
            <div className='title-area'>
                {migration['type'] == 'Soft data migration' ?
                    <p className='text-simple'>
                        Review the mapping properties and make any adjustments you deem necessary.
                    </p>
                    :
                    <>
                        <p className='text-simple'>
                            Field mappings are the link between Salesforce properties and HubSpot properties. <br />
                            If a field or property isn't mapped below, its data won't be included in the migration. <br />
                            Field mappings can be set up one by one or in bulk by CSV.
                        </p>
                        {migration['paymentStatus'] != 'paid' && <div className='select-area'>
                            <label id='supported-objects-multiple-checkbox-label' onClick={() => setOpenDropdown(!openDropdown)} style={{ marginRight: '0px', fontWeight: 'bold' }}>
                                {supportedObjects.length > 0 ? `Selected Objects to Migrate - ${supportedObjects.length}` : `Select Objects`}
                            </label>
                            <Select
                                name='supportedObjects'
                                id='supported-objects-multiple-checkbox'
                                labelId='supported-objects-multiple-checkbox-label'
                                value={[]} multiple onChange={objectsDropdownChange}
                                open={openDropdown} onClose={() => setOpenDropdown(false)} onOpen={() => setOpenDropdown(true)}
                            >
                                {objects.map(({ label, key }) => <MenuItem value={key}> <Checkbox checked={supportedObjects.includes(key)} /> {label} </MenuItem>)}
                            </Select>
                        </div>}
                    </>
                }

                {/* New Design title show this  */}
                {/* <p>Review the mapping properties and make any adjustments you deem necessary.</p> */}
            </div>
            {downloadFields && <CSVLink id='Fields-CSV' data={downloadFields['csvDate']} filename={downloadFields['filename']}></CSVLink>}
            {migration['type'] == 'Soft data migration' &&
                <div className='table-area'>
                    <div class='table-responsive'>
                        <table class='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <i className='icon'><img src={require('../../static/images/salesforce.png')} alt='' /></i>
                                        Salesforce object
                                    </th>
                                    <th>
                                        <i className='icon'>
                                            <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                        <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                            <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </i>
                                        HubSpot property ID
                                        <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                            <div className='overlabox-tooltip'> <p>This is HubSpot Property for Salesforce Internal ID. </p> </div>
                                        </i>
                                    </th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* New Design Bottom Table show this TR  */}
                                {objectsData.map(({ plural, source, objectType }, index) => {
                                    let objectFieldsMapping = objectsFieldMapping?.[source.toLocaleLowerCase()];
                                    let hubspotLabel = objectFieldsMapping?.['hubspotProperties'];
                                    return (
                                        <tr>
                                            <td> {source} </td>
                                            <td>
                                                <Autocomplete
                                                    disablePortal
                                                    sx={{ width: 300 }}
                                                    options={hubspotLabel}
                                                    id={`hubspotLabel_${objectType}`}
                                                    value={hubspotMap?.[source] || ''}
                                                    // PaperComponent={({ children }) => <Paper>{children}</Paper>}
                                                    getOptionDisabled={option => {
                                                        let sfField = salesforceProp?.[objectType];
                                                        if (option['label'] == 'Add New') return false;
                                                        else if (sfField && sourceTypes[sfField['type']] && !sourceTypes[sfField['type']].find(({ type }) => option['type'] == type)) return true;
                                                        else return false;
                                                    }}
                                                    getOptionLabel={option => {
                                                        if (!option['label']) return ``;
                                                        else if (option['label'] == 'Add New' ) return option['label'];
                                                        else return `${option['label']} ${option['name']} ${option['type'] && (option['type'])}`;
                                                    }}
                                                    renderOption={(props, option) => {
                                                        const { key, ...optionProps } = props;
                                                        return (
                                                            <Box key={key} component='li' {...optionProps} style={{ 'fontSize': '14px' }}>
                                                                <span> {option['label']} </span>
                                                                {option['type'] &&
                                                                    <>
                                                                        &nbsp; - &nbsp;
                                                                        <span style={{ 'color': '#5C5C5C' }}> {option['name']} ({option['type']}) </span>
                                                                    </>
                                                                }
                                                            </Box>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label='Select a propery ID' />}
                                                    onChange={(e, property) => setHubspotMapFn(source, property)}
                                                />
                                            </td>
                                            <td scope='row'><button className='edit-btn edit-mapping' onClick={() => deleteMapping(source)}> <i className='icon'><img src={require('../../static/images/remove-btn.png')} alt='' /></i></button></td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {objectsData.map(({ objectType, plural, source, sources }) => {

                let objectFieldsMapping = objectsFieldMapping?.[source.toLocaleLowerCase()];
                let sourceLabel = objectFieldsMapping?.['providerFields'];
                let hubspotLabel = objectFieldsMapping?.['hubspotProperties'];

                // Add New Option
                let found = hubspotLabel?.find(({ label }) => label == 'Add New');
                if (!found) hubspotLabel?.push({ label: 'Add New' });

                // Show if 
                if (supportedObjects.find(x => x.indexOf(sources) >= 0))
                    return (
                        <div className='table-area'>
                            <div className='add-property'>
                                <p className='acounts'>From <Link to='#'>{sources}</Link> to <Link to='#'>{capitalize(plural)}</Link></p>
                                <div className='right-area'>
                                    <button className='btn-style-two' onClick={() => setNewProperty({ ...newProperty, [source]: newProperty[source] ? false : true })}><i class='fa fa-plus fa-lg mr-2' style={{ color: 'grey' }} />Add property</button>
                                    <button className='btn-style-two' onClick={() => dispatch(downloadCSV({ key: migration['key'], objectType: source }))}><i class='fa fa-download fa-lg mr-2' />Download CSV</button>
                                    <button className='btn-style-two' onClick={() => clickUploadFile(source)}>
                                        <i class='fa fa-upload fa-lg mr-2' />  Upload CSV
                                        <input id={`mapping-${source}`} accept='.csv' hidden type='file' enctype='multipart/form-data' onChange={handleFileUpload} />
                                    </button>
                                </div>
                            </div>
                            <div class='table-responsive'>
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                <i className='icon'><img src={require('../../static/images/salesforce.png')} alt='' /></i>
                                                Salesforce property
                                                <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                    <div className='overlabox-tooltip'> <p>This is Salesforce Property Label. </p> </div>
                                                </i>
                                            </th>
                                            <th>
                                                <i className='icon'>
                                                    <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                    <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </i>
                                                HubSpot property
                                                <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                    <div className='overlabox-tooltip'> <p>This is HubSpot Property Label. </p> </div>
                                                </i>
                                            </th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newProperty?.[source] &&
                                            <tr>
                                                <td>
                                                    <Autocomplete
                                                        disablePortal
                                                        sx={{ width: 300 }}
                                                        options={sourceLabel}
                                                        id={`sourceLabel_${objectType}`}
                                                        getOptionLabel={option => `${option['label']} ${option['name']} (${option['type']})`}
                                                        getOptionDisabled={option => option['type'] == 'reference' ? true : false}
                                                        renderOption={(props, option) => {
                                                            const { key, ...optionProps } = props;
                                                            return (
                                                                <Box key={key} component='li' {...optionProps} style={{ 'fontSize': '14px' }}>
                                                                    <span>
                                                                        {option['label']}
                                                                        &nbsp; - &nbsp;
                                                                        <span style={{ 'color': '#5C5C5C' }}>
                                                                            {option['name']} ({option['type']})
                                                                            {option['type'] == 'reference' && <span style={{ 'fontSize': '12px' }}><br />Unsupported field type.</span>}
                                                                        </span>
                                                                    </span>
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={params => <TextField {...params} label='Salesforce Label' />}
                                                        onChange={(e, property) => setSalesforceProp({ ...salesforceProp, [objectType]: property })}
                                                    />
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        disablePortal
                                                        sx={{ width: 300 }}
                                                        options={hubspotLabel}
                                                        id={`hubspotLabel_${objectType}`}
                                                        // value={hubspotProp?.[objectType]?.['label'] || ''}
                                                        // PaperComponent={({ children }) => <Paper>{children}</Paper>}
                                                        getOptionDisabled={option => {
                                                            let sfField = salesforceProp?.[objectType];
                                                            if (option['label'] == 'Add New') return false;
                                                            else if (sfField && sourceTypes[sfField['type']] && !sourceTypes[sfField['type']].find(({ type }) => option['type'] == type)) return true;
                                                            else return false;
                                                        }}
                                                        getOptionLabel={option => {
                                                            if (option['label'] == 'Add New') return option['label'];
                                                            else return `${option['label']} ${option['name']} ${option['type'] && (option['type'])}`;
                                                        }}
                                                        renderOption={(props, option) => {
                                                            const { key, ...optionProps } = props;
                                                            return (
                                                                <Box key={key} component='li' {...optionProps} style={{ 'fontSize': '14px' }}>
                                                                    <span> {option['label']} </span>
                                                                    {option['type'] &&
                                                                        <>
                                                                            &nbsp; - &nbsp;
                                                                            <span style={{ 'color': '#5C5C5C' }}> {option['name']} ({option['type']}) </span>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label='HubSpot Label' />}
                                                        onChange={(e, property) => openNewHubProperty(property, objectType, source)}
                                                    />
                                                </td>
                                                <td scope='row'><button className='edit-btn' onClick={() => saveNewField(objectType, source)}> <i className='fa fa-save fa-lg' style={{ color: 'grey' }} /> Save</button></td>
                                            </tr>
                                        }
                                        {objectFieldsMapping?.['mappedFields'] && objectFieldsMapping?.['mappedFields'].map(field => (
                                            <tr>
                                                <td>{field['sourceLabel']}</td>
                                                <td>{field['hubspotLabel']}</td>
                                                {field['hubspotStatus'] != 'default' ?
                                                    <td scope='row'>
                                                        <button className='edit-btn' onClick={() => {
                                                            setAction('edit');
                                                            setEditField(field);
                                                            setNewModal(objectType);
                                                        }}>
                                                            <i className='icon'><img src={require('../../static/images/edit-icon.png')} alt='' /></i> Edit
                                                        </button>
                                                    </td>
                                                    : <td> Read Only </td>
                                                }
                                                <td scope='row'><button className='edit-btn' onClick={() => dispatch(removeField({ key: migration['key'], fieldId: field['_id'] }))}> <i className='icon'><img src={require('../../static/images/remove-btn.png')} alt='' /></i> Remove</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
            })}

            {/* ------------------New Property Modal----------------- */}
            <Modal
                right
                isOpen={newModal ? true : false}
                className='modal-payment label-modal right'
                aria-labelledby='contained-modal-title-vcenter'
            >
                <ModalHeader>
                    <button onClick={() => setNewModal('')}>X</button>
                </ModalHeader>
                <ModalBody style={{ 'overflow-y': 'scroll' }}>
                    <AddProperty
                        action={action}
                        source={source}
                        editField={editField}
                        setNewModal={setNewModal}
                        sourceTypes={sourceTypes}
                        salesforceProp={salesforceProp[newModal]}
                    />
                </ModalBody>
            </Modal>


            {/* ------------------Error Modal----------------- */}
            <Modal
                isOpen={errorMessage ? true : false}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'>
                            <h2>Mapping Error</h2>
                        </div>
                        <div className='pt-3'> {errorMessage} </div>
                        <div className='text-center pt-3'>
                            <button className='btn-style-one' onClick={() => dispatch(updateErrorPopup(''))}> Got it! </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

const objects = [
    { label: 'Opportunities', key: 'Opportunities_HardData' }, { label: 'Leads', key: 'Leads_HardData' }, { label: 'Contacts', key: 'Contacts_HardData' }, { label: 'Accounts', key: 'Accounts_HardData' },
    { label: 'Notes', key: 'Notes_SoftData' }, { label: 'Tasks', key: 'Tasks_SoftData' }, { label: 'Events', key: 'Events_SoftData' }, { label: 'Emails', key: 'Email-Message_SoftData' }, { label: 'Attachments', key: 'Attachments_SoftData' }
]

export default FieldMapping;