import { useState } from "react";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const customTheme = createTheme({
    components: {
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    color: "#091B4D",
                    fontWeight: "bold",
                },
                label: {
                    fontSize: "14px",
                    fontWeight: "bold",
                }
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#0065FF",
                    textTransform: "uppercase",
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: "#091B4D",
                    fontSize: "12px",
                    fontWeight: "500",
                    "&.Mui-selected": {
                        backgroundColor: "#091B4D",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#091B4D",
                        },
                    },
                    "&.Mui-disabled": {
                        color: "#D3D3D3",
                    },
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
              root: {
                fontSize: "10px",
                color: "#FF5733",
                "&:hover": {
                  color: "#FF5733",
                },
              },
            },
          },
    },
});

export default function CustomStyledDatePicker({ date, setDate }) {

    return (
        <ThemeProvider theme={customTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                    style={{
                        background: "white",
                        borderRadius: "12px",
                        border: '1px solid #E8EFFF',
                        height: '100%'
                    }}
                >
                    <DateCalendar
                        value={date}
                        onChange={setDate}
                        disableHighlightToday
                    />
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
