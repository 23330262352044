import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { downloadFile } from '../../../store/actions/Migration';

export default function MigrationSummery({ setSection }) {

    const dispatch = useDispatch();
    const { migration, newDeltaMigration } = useSelector(st => st['Migration']);
    
    const [deleteObj, setDeleteObj] = useState('');
    const [restartObj, setRestartObj] = useState('');
    const [migrationData, setMigrationData] = useState({});
    const [summaryType, setSummaryType] = useState('Standard Objects');  // Custom Objects | Standard Objects

    useEffect(() => {
        let migrationData = migration['deltaMigrations'].filter(delta => delta['name'] == newDeltaMigration['name'])[0];
        setMigrationData(migrationData);
    }, [newDeltaMigration]);

    return (
        <div className='summry-tab'>
            <div className='result-tabs-area'>
                <div className='record-area'>
                    <div className='record-box'>
                        <p>Migrated Records</p>
                        <h2>{migrationData?.['migratedCount'] || 0}</h2>
                    </div>
                    <div className='record-box'>
                        <p>In-Queue Tasks</p>
                        <h2>{migrationData['inQueueTasks'] || 0}</h2>
                    </div>
                    <div className='record-box'>
                        <p>Errors Found</p>
                        <h2>{migrationData?.['totalErrors'] || 0}</h2>
                    </div>
                    <div className='record-box'>
                        <p>
                            Total Records
                            <i className='icon-info'><img src={require('../../../static/images/info-icon.png')} alt='' />
                                <div className='overlabox-tooltip'> <p>These are total number of record counted in salesforce. </p> </div>
                            </i>
                        </p>
                        <h2>{migrationData?.['totalRecords'] || 0}</h2>
                    </div>
                </div>
                <div className='table-summry'>
                    <div class='table-responsive'>
                        <i class='fa fa-arrow-left' onClick={() => setSection('home')} style={{ 'color': 'blue' }} />
                        <table class='table'>
                            <tbody>
                                {/* TODO Filter Custom Objects (migrationData['objects']) before mapping them. Also user can select Tab */}
                                {migrationData['objects'] && Object.values(migrationData['objects']).map(row => {
                                    console.log('********** migrationData = ', migrationData);
                                    return (
                                        <tr>
                                            {row.filter(object => summaryType == 'Custom Objects' && object['custom'] || summaryType == 'Standard Objects' && !object['custom']).map(object => {

                                                let updation = object['hsObjectUpdate'] || {};

                                                let insertion = object['hsObject'] || {};
                                                let extractCount = (object['noOfRecord'] && object['count'] > object['noOfRecord']) ? object['noOfRecord'] : (object['count'] || 0);
                                                let insertCount = (insertion['noOfRecord'] && insertion['count'] > insertion['noOfRecord']) ? insertion['noOfRecord'] : (insertion['count'] || 0);
                                                let percent = (parseFloat((object['migratedCount'] + (insertion?.['migratedCount'] || 0)) / (object['count'] + insertCount) * 100).toFixed(0)) || 0;
                                                if (isNaN(percent)) percent = 0;

                                                // Associations status
                                                let associationStatus;
                                                if (object['hsAssociations'] && object['hsAssociations'].length > 0)
                                                    for (let association of object['hsAssociations'])
                                                        if (!associationStatus || associationStatus == 'Completed') associationStatus = association['status'];

                                                // console.log(`********* ${object['label']} object = `, object);
                                                return (
                                                    <td>
                                                        <div className='download-data'>
                                                            <div className='progress-bar-circle'>
                                                                <div className='progress-inner' style={{ width: 36, height: 36 }}>
                                                                    <CircularProgressbar
                                                                        value={percent}
                                                                        strokeWidth={50}
                                                                        styles={buildStyles({ strokeLinecap: 'butt' })}
                                                                    />
                                                                    {object['status'] == 'In-Progress' && <p>{percent}%</p>}
                                                                    {object['status'] == 'Error' && <p><img src={require('../../../static/images/error-icon.png')} alt='' /></p>}
                                                                    {object['status'] == 'Completed' && <p><img src={require('../../../static/images/check-icon.png')} alt='' /></p>}
                                                                </div>
                                                                <div className='right-area'>
                                                                    <h5>
                                                                        {_.capitalize(object['label'])}
                                                                        {object['label'] == 'tasks' &&
                                                                            <i className='icon-info'><img src={require('../../../static/images/info-icon.png')} alt='' />
                                                                                <div className='overlabox-tooltip'> <p>These are subtype Task in Salesforce! </p> </div>
                                                                            </i>
                                                                        }
                                                                    </h5>
                                                                    {object['type'] === 'retrieve' ?
                                                                        <>
                                                                            {object['status'] == 'In-Queue' ?
                                                                                <p>
                                                                                    <i className='icon' style={{ marginRight: '5px' }}>
                                                                                        <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                                    <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                        <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </i>
                                                                                    Retrieve Pending...
                                                                                </p>
                                                                                :
                                                                                <p>
                                                                                    <i className='icon' style={{ marginRight: '5px' }}>
                                                                                        <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                                    <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                        <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </i>
                                                                                    Retrieved {(object['migratedCount'] > extractCount ? extractCount : object['migratedCount']) || 0} {` of `} {extractCount}
                                                                                    {object['errors']?.length > 0 && <>&nbsp;({object['errors'].length} {`Error${object['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                                </p>
                                                                            }
                                                                        </>
                                                                        :
                                                                        object['status'] == 'In-Queue' ?
                                                                            <p>
                                                                                <i className='icon' style={{ marginRight: '5px' }}><img style={{ width: '23px' }} src={require('../../../static/images/salesforce.png')} alt='' /></i>
                                                                                Export Pending...
                                                                            </p>
                                                                            :
                                                                            <p>
                                                                                <i className='icon' style={{ marginRight: '5px' }}><img style={{ width: '23px' }} src={require('../../../static/images/salesforce.png')} alt='' /></i>
                                                                                Exported {(object['migratedCount'] > extractCount ? extractCount : object['migratedCount']) || 0} {` of `} {extractCount}
                                                                                {object['errors']?.length > 0 && <>&nbsp;({object['errors'].length} {`Error${object['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                            </p>
                                                                    }
                                                                    {insertion['status'] && <> {insertion['status'] == 'In-Queue' ?
                                                                        <p>
                                                                            <i className='icon' style={{ marginRight: '5px' }}>
                                                                                <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                            <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Import Pending...
                                                                        </p>
                                                                        : <p>
                                                                            <i className='icon' style={{ marginRight: '5px' }}>
                                                                                <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                            <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Imported {(insertion['migratedCount'] > insertCount ? insertCount : insertion['migratedCount']) || 0} {` of `} {insertCount}
                                                                            {insertion?.['errors']?.length > 0 && <>&nbsp;({insertion['errors'].length} {` Error${insertion['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                        </p>
                                                                    }</>}

                                                                    {updation['status'] && <> {updation['status'] == 'In-Queue' ?
                                                                        <p>
                                                                            <i className='icon' style={{ marginRight: '5px' }}>
                                                                                <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                            <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Update Pending...
                                                                        </p>
                                                                        : <p>
                                                                            <i className='icon' style={{ marginRight: '5px' }}>
                                                                                <svg width="20px" height="20px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g id="💻-Page-Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <g id="Migration-Details-Summary-Copy" transform="translate(-658, -613)" fill="#F95C35" fill-rule="nonzero">
                                                                                            <g id="Group-13" transform="translate(318.0333, 529.0333)">
                                                                                                <path d="M345.593689,89.7168497 C344.997244,89.7168497 344.513747,89.2414815 344.513747,88.6551667 C344.513747,88.0687501 344.997244,87.5933819 345.593689,87.5933819 C346.190133,87.5933819 346.673631,88.0687501 346.673631,88.6551667 C346.673631,89.2414815 346.190133,89.7168497 345.593689,89.7168497 M345.916987,86.6110627 L345.916987,85.6665408 C346.167751,85.5500929 346.343389,85.3006926 346.343389,85.0112539 L346.343389,84.9894517 C346.343389,84.5899834 346.010972,84.263155 345.604673,84.263155 L345.582601,84.263155 C345.176302,84.263155 344.843885,84.5899834 344.843885,84.9894517 L344.843885,85.0112539 C344.843885,85.3006926 345.019523,85.5501948 345.270287,85.6666427 L345.270287,86.6110627 C344.896939,86.6678094 344.555817,86.8192019 344.274485,87.0420117 L341.636804,85.0247019 C341.654212,84.9589898 341.66644,84.8913421 341.666543,84.8202304 C341.666958,84.3678868 341.294543,84.0006123 340.834257,84.0000005 C340.374178,83.9994917 340.000519,84.3657473 340.000001,84.8181928 C339.999483,85.2706383 340.371898,85.6379127 340.832184,85.6384221 C340.982124,85.6386259 341.120977,85.5967536 341.242628,85.5286983 L343.837203,87.5132029 C343.616593,87.8406427 343.487274,88.2328775 343.487274,88.6551667 C343.487274,89.0972205 343.629339,89.5061636 343.869326,89.8424668 L343.080353,90.6182768 C343.017973,90.5998366 342.953313,90.5869999 342.884716,90.5869999 C342.506602,90.5869999 342.199986,90.8883585 342.199986,91.2601157 C342.199986,91.6319747 342.506602,91.9333333 342.884716,91.9333333 C343.262934,91.9333333 343.569446,91.6319747 343.569446,91.2601157 C343.569446,91.1928754 343.556389,91.1292009 343.537634,91.0678697 L344.31811,90.3005156 C344.672391,90.5663184 345.113611,90.726065 345.593689,90.726065 C346.757046,90.726065 347.7,89.7988625 347.7,88.6551667 C347.7,87.6197686 346.926363,86.7643909 345.916987,86.6110627" id="Marketing/Logos/Sprocket"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Updated {(updation['migratedCount'] > insertCount ? insertCount : updation['migratedCount']) || 0} {` of `} {insertCount}
                                                                            {updation?.['errors']?.length > 0 && <>&nbsp;({updation['errors'].length} {` Error${updation['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                        </p>
                                                                    }</>}

                                                                    {associationStatus == 'In-Progress' ? <p> Association In Progress </p>
                                                                        : associationStatus == 'Completed' ? <p> Associated </p>
                                                                            : associationStatus == 'Error' ? <p> Association Error <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></p> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='right-btn'>
                                                                {(object['errors']?.length > 0 || insertion['errors']?.length > 0 || ((migrationData['inQueueTasks'] == 0 || !migrationData['inQueueTasks']) && (insertion['migratedCount'] < insertCount || object['migratedCount'] < extractCount))) &&
                                                                    <button className='download-btn' onClick={() => setRestartObj(object)}><i className='fa fa-refresh' style={{ 'color': '#0065ff' }} /></button>
                                                                }
                                                                {percent == 100 && <button className='download-btn' onClick={() => dispatch(downloadFile({ key: migrationData['key'], objectType: object['key'] }))}><img src={require('../../../static/images/download-btn.png')} alt='' /></button>}
                                                                {percent > 0 && object['key'].indexOf('user') < 0 && <button className='download-btn' onClick={() => setDeleteObj(object)}><i className='fa fa-trash'></i></button>}
                                                            </div>
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}