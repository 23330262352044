import { useState } from "react";
import MigrationSummery from "./summery";
import DeltaMigrationTabPanelHome from "./home";
import DeltaMigrationTabPanelSetup from "./setup";
import DeltaMigrationTabPanelOverview from "./overview";

export default function DeltaMigrationTabPanel() {

    const [section, setSection] = useState('home');
    return (
        <>
            {section === 'home' && <DeltaMigrationTabPanelHome setSection={setSection} />}
            {section === 'overview' && <DeltaMigrationTabPanelOverview setSection={setSection} />}
            {section === 'setup' && <DeltaMigrationTabPanelSetup setSection={setSection} />}
            {section === 'summery' && <MigrationSummery setSection={setSection} />}
        </>
    )
}