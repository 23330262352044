import { setActiveTab } from "../actions/Migration";

let initialState = {
  sfAuth: '',
  hsAuth: '',
  errors: [],
  results: [],
  modalObj: {},
  activeTab: 0,
  migration: {},
  migrations: [],
  resultPages: [],
  recordCount: 0,
  stripeSecret: {},
  nameModal: false,
  errorMessage: '',
  paymentStatus: '',
  downloadFields: '',
  totalRecords: '',
  supportedObjects: [],
  newDeltaMigration: {},
  objectsFieldMapping: {},
};

const Migration = (state = initialState, { type, payload }) => {

  switch (type) {
    case 'SET_MIGRATION':
      if (payload['type'] === 'redirect') window.location.replace(`/NewMigration?key=${payload['data']['key']}`);
      return {
        ...state,
        migration: payload['data'],
        sfAuth: payload['data']['sfAuth'],
        hsAuth: payload['data']['hsAuth'],
        errors: payload['data']['errors'],
        results: payload['data']['results'],
        resultPages: payload['data']['resultPages'],
        totalRecords: payload['data']['resultTotal'],
        stripeSecret: payload['data']['stripeSecret'],
      };

    case 'SET_MIGRATIONS':
      return {
        ...state,
        sfAuth: payload['sfAuth'],
        hsAuth: payload['hsAuth'],
        migrations: payload['migrations'],
      };

    case 'SET_DOWNLOAD_CSV':
      return {
        ...state,
        downloadFields: payload
      };

    case 'SET_FIELDS_MAPPING':
      return {
        ...state,
        objectsFieldMapping: payload
      };

    case 'SET_SUPPORTED_OBJECTS':
      return {
        ...state,
        supportedObjects: payload
      };

    case 'SET_MODAL_OBJ':
      return {
        ...state,
        modalObj: payload // title, body, type = 'Confirm', 'Acknowledge'
      };

    case 'SET_RESULTS':
      return {
        ...state,
        results: payload,
      };

    case 'SET_RESULT_PAGES':
      return {
        ...state,
        resultPages: payload,
      };

    case 'SET_RESULT_TOTAL':
      return {
        ...state,
        totalRecords: payload,
      };

    case 'SET_ERRORS':
      return {
        ...state,
        errors: payload,
      };

    case 'UPDATE_ERROR_POPUP':
      return {
        ...state,
        errorMessage: payload,
      };

    case 'SET_STRIPE_SECRET':
      return {
        ...state,
        stripeSecret: {
          secret: payload['clientSecret'],
          amount: payload['amount']
        },
      };

    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: payload,
      };

    case 'SET_DELTA_MIGRATION':
      return {
        ...state,
        newDeltaMigration: payload,
      };

    case 'SET_RECORD_COUNT':
      return {
        ...state,
        recordCount: payload['recordCount'],
        newDeltaMigration: payload['newDeltaMigration'],
        stripeSecret: {
          amount: payload['amount'],
          secret: payload['secret'],
        },
      };

    default:
      return state;
  }
};

export default Migration;